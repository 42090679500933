import useSoundLib from 'use-sound';

type UseSoundOptions = {
  playbackRate?: number;
  volume?: number;
};

export const useSound = (soundPath: string, options: UseSoundOptions = {}) => {
  const playbackRate = options.playbackRate || Math.random() * 0.3 + 0.8;
  const baseVolume = options.volume || 0.05;
  const randomVolume = 1 + Math.random() * 0.1;
  const volume = baseVolume * randomVolume;

  const [play] = useSoundLib(soundPath, {
    soundEnabled: true,
    volume,
    playbackRate
  });

  return play;
};
